import React,{useState} from 'react';
import './Banner.css'; // Create this CSS file for custom styles
import EnquiryForm from './EnquiryForm';

const Banner = ({ title, subtitle, backgroundImage }) => {
	const [enquire,setEnquire]=useState(false);
  
	return (
	<>
  <div className="banner" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <div className="banner-content">
      <br/><br/><h1 className="banner-title">{title}</h1>
      <p>{subtitle}</p>
	  <button onClick={()=>{setEnquire(true);}} className="w3-text-white" style={{fontWeight:"600",background:"#ec753c",border:"none",borderRadius:"10px",padding:"10px"}}>Enquire</button>
    </div>
  </div>
    {enquire && <EnquiryForm setEnquire={setEnquire}/>}
  </>
);
};

export default Banner;
