import React,{useState,useRef} from 'react';
import "./BlockEntry.css";
import ImageCarousel from './ImageCarousel';
const BlogEntry = ({ images,more,title, imgSrc, videoSrc, content, isVideo }) =>{
  const [showCarousel,setShowCarousel]=useState(false);
  const [selectedCategory, setSelectedCategory]=useState();
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 992px)").matches);
  const menuRef = useRef(null);
  
  const setCategory=(cat)=>{
	setShowCarousel(true);
	setSelectedCategory(cat);
  };
	return (
	<>
  <div className="blog-entry w3-card w3-margin  w3-margin-top w3-white" style={{paddingTop:"10px",paddingBottom:"10px"}}>
     <h4><b>{title}</b></h4>
   
	{isVideo ? (
      <center>
        <video src={videoSrc} loop autoPlay muted style={{ width: '100%', background: 'black' }} alt={title}></video>
      </center>
    ) : (
      <img src={imgSrc} alt={title} style={{ width: '100%' }} />
    )}
    <div className="w3-container w3-white">
      <p>{content}</p>
      <button onClick={()=>{setCategory(more);}} style={{float:"left"}} className="w3-button w3-padding-large w3-white w3-border"><b>Explore</b></button>
    </div>

  </div>
    {showCarousel && <ImageCarousel images={images} setShowCarousel={setShowCarousel} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>}
   
  </>
);};

export default BlogEntry;
