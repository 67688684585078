import React from 'react';

const TikTokIcon = ({ width = '32px', height = '32px', fill = '#000' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.45095 19.7926C8.60723 18.4987 9.1379 17.7743 10.1379 17.0317C11.5688 16.0259 13.3561 16.5948 13.3561 16.5948V13.2197C13.7907 13.2085 14.2254 13.2343 14.6551 13.2966V17.6401C14.6551 17.6401 12.8683 17.0712 11.4375 18.0775C10.438 18.8196 9.90623 19.5446 9.7505 20.8385C9.74562 21.5411 9.87747 22.4595 10.4847 23.2536C10.3345 23.1766 10.1815 23.0889 10.0256 22.9905C8.68807 22.0923 8.44444 20.7449 8.45095 19.7926ZM22.0352 6.97898C21.0509 5.90039 20.6786 4.81139 20.5441 4.04639H21.7823C21.7823 4.04639 21.5354 6.05224 23.3347 8.02482L23.3597 8.05134C22.8747 7.7463 22.43 7.38624 22.0352 6.97898ZM28 10.0369V14.293C28 14.293 26.42 14.2312 25.2507 13.9337C23.6179 13.5176 22.5685 12.8795 22.5685 12.8795C22.5685 12.8795 21.8436 12.4245 21.785 12.3928V21.1817C21.785 21.6711 21.651 22.8932 21.2424 23.9125C20.709 25.246 19.8859 26.1212 19.7345 26.3001C19.7345 26.3001 18.7334 27.4832 16.9672 28.28C15.3752 28.9987 13.9774 28.9805 13.5596 28.9987C13.5596 28.9987 11.1434 29.0944 8.96915 27.6814C8.49898 27.3699 8.06011 27.0172 7.6582 26.6277L7.66906 26.6355C9.84383 28.0485 12.2595 27.9528 12.2595 27.9528C12.6779 27.9346 14.0756 27.9528 15.6671 27.2341C17.4317 26.4374 18.4344 25.2543 18.4344 25.2543C18.5842 25.0754 19.4111 24.2001 19.9423 22.8662C20.3498 21.8474 20.4849 20.6247 20.4849 20.1354V11.3475C20.5435 11.3797 21.2679 11.8347 21.2679 11.8347C21.2679 11.8347 22.3179 12.4734 23.9506 12.8889C25.1204 13.1864 26.7 13.2483 26.7 13.2483V9.91314C27.2404 10.0343 27.7011 10.0671 28 10.0369Z"
        fill="#ffffff"
      />
      <path
        d="M26.7009 9.91314V13.2472C26.7009 13.2472 25.1213 13.1853 23.9515 12.8879C22.3188 12.4718 21.2688 11.8337 21.2688 11.8337C21.2688 11.8337 20.5444 11.3787 20.4858 11.3464V20.1364C20.4858 20.6258 20.3518 21.8484 19.9432 22.8672C19.4098 24.2012 18.5867 25.0764 18.4353 25.2553C18.4353 25.2553 17.4337 26.4384 15.668 27.2352C14.0765 27.9539 12.6788 27.9357 12.2604 27.9539C12.2604 27.9539 9.84473 28.0496 7.66995 26.6366L7.6591 26.6288C7.42949 26.4064 7.21336 26.1717 7.01177 25.9257C6.31777 25.0795 5.89237 24.0789 5.78547 23.7934C5.78529 23.7922 5.78529 23.791 5.78547 23.7898C5.61347 23.2937 5.25209 22.1022 5.30147 20.9482C5.38883 18.9122 6.10507 17.6625 6.29444 17.3494C6.79597 16.4957 7.44828 15.7318 8.22233 15.0919C8.90538 14.5396 9.6796 14.1002 10.5132 13.7917C11.4144 13.4295 12.3794 13.2353 13.3565 13.2197V16.5948C13.3565 16.5948 11.5691 16.028 10.1388 17.0317C9.13879 17.7743 8.60812 18.4987 8.45185 19.7926C8.44534 20.7449 8.68897 22.0923 10.0254 22.991C10.1813 23.0898 10.3343 23.1775 10.4845 23.2541C10.7179 23.5576 11.0021 23.8221 11.3255 24.0368C12.631 24.8632 13.7249 24.9209 15.1238 24.3842C16.0565 24.0254 16.7586 23.2167 17.0842 22.3206C17.2888 21.7611 17.2861 21.1978 17.2861 20.6154V4.04639H20.5417C20.6763 4.81139 21.0485 5.90039 22.0328 6.97898C22.4276 7.38624 22.8724 7.7463 23.3573 8.05134C23.5006 8.19955 24.2331 8.93231 25.1734 9.38216C25.6596 9.61469 26.1722 9.79285 26.7009 9.91314Z"
        fill="#ffffff"
      />
      <path
        d="M4.48926 22.7568V22.7594L4.57004 22.9784C4.56076 22.9529 4.53074 22.8754 4.48926 22.7568Z"
        fill="#ffffff"
      />
      <path
        d="M10.4844 23.2541C9.87716 22.46 9.74531 21.5416 9.75018 20.839C9.90655 19.5447 10.4383 18.8196 11.4378 18.0775C12.8685 17.0712 14.6553 17.6401 14.6553 17.6401V13.2966C14.2256 13.2343 13.791 13.2085 13.3564 13.2197V16.5948C12.3794 16.6104 11.4145 16.8046 10.5133 17.1667C9.67968 17.4752 8.90547 17.9146 8.22242 18.4669C7.44836 19.1067 6.79606 19.8706 6.29454 20.7244C6.10518 21.0375 5.38894 22.2873 5.30158 24.3233C5.25221 25.4773 5.61359 26.6688 5.78559 27.1649C5.78541 27.1661 5.78541 27.1673 5.78559 27.1685C6.03383 27.8832 6.53583 29.1163 7.51118 30.0637C7.52671 30.0781 7.54231 30.0921 7.55793 30.106C7.55793 30.106 7.82661 30.3589 8.2649 30.6853C9.25448 31.4198 11.3676 32.3768 14.2112 31.7814C15.5169 31.5114 16.6049 30.8582 17.423 30.2236C19.1885 29.4277 20.1896 28.2446 20.1896 28.2446C20.3409 28.0657 21.164 27.1904 21.6974 25.8569C22.1059 24.8376 22.2399 23.6156 22.2399 23.1263V12.8826C22.2399 12.8826 23.2899 13.5207 24.9226 13.9368C26.0918 14.2343 27.6708 14.2961 27.6708 14.2961V10.04C27.2148 9.98692 26.7548 9.92012 26.2931 9.8372C26.2922 9.83724 26.2913 9.83743 26.2904 9.83766C25.7618 9.71742 25.249 9.53925 24.7628 9.30671C23.8225 8.85686 23.0899 8.1241 22.9467 7.97589C22.4619 7.67085 22.0171 7.31079 21.6223 6.90353C20.638 5.82494 20.2657 4.73594 20.1312 3.97094H17.2671V20.6258C17.2671 21.2082 17.2698 21.7715 17.0652 22.331C16.7396 23.2271 16.0375 24.0358 15.1048 24.3946C13.7059 24.9313 12.6119 24.8736 11.3064 24.0472C10.983 23.8325 10.6988 23.568 10.4654 23.2645C10.459 23.2602 10.4544 23.257 10.4515 23.2548C10.462 23.2627 10.473 23.2711 10.4844 23.2799V23.2541Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default TikTokIcon;
