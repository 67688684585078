import React, { useState,useRef,useEffect } from 'react';
import './Header.css';
import ImageCarousel from './ImageCarousel';

const Header = ({images}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showCarousel,setShowCarousel]=useState(false);
  const [selectedCategory, setSelectedCategory]=useState();
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 992px)").matches);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    if (isMobile) {
      setIsMenuOpen(!isMenuOpen);
    }
  };
  
  const setCategory=(cat)=>{
	toggleMenu();
	setShowCarousel(true);
	setSelectedCategory(cat);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 992px)").matches);
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside); // Listen for clicks outside the menu

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside); // Clean up listener
    };
  }, [isMenuOpen]);
  
   
  return (
    <header className="header">
      <div className="navbar">
        <h1 className="logo">
          <img id="wave-logo" src="images/mainlogo.png" alt="Logo" />
        </h1>
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <nav ref={menuRef} style={{marginRight:"10px"}} className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <span onClick={()=>{setCategory("weddings");}} className="text-shadow menu-item w3-bold w3-text-white">Weddings</span>
          <span onClick={()=>{setCategory("birthdays");}} className="text-shadow menu-item w3-bold w3-text-white">Birthdays</span>
          <span onClick={()=>{setCategory("corporate");}} className="text-shadow menu-item w3-bold w3-text-white">Corporate</span>
          <span onClick={()=>{setCategory("graduations");}} className="text-shadow menu-item w3-bold w3-text-white">Graduations</span>
          <span onClick={()=>{setCategory("given");}} className="text-shadow menu-item w3-bold w3-text-white">Meet Given</span>
       
	   </nav>
      </div>
	  {showCarousel && <ImageCarousel images={images} setShowCarousel={setShowCarousel} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>}
	</header>
  );
};

export default Header;
