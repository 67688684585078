 import React,{useState,useRef} from 'react';
 import ImageCarousel from './ImageCarousel';
  const IntroVideo=({images})=>{
	  
  const [showCarousel,setShowCarousel]=useState(false);
  const [selectedCategory, setSelectedCategory]=useState();
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 992px)").matches);
  const menuRef = useRef(null);
  
  const setCategory=(cat)=>{
	setShowCarousel(true);
	setSelectedCategory(cat);
  };
  return(<>
   <div className="w3-container w3-black w3-margin w3-card" style={{paddingLeft:"0px",paddingBottom:"0px"}}>
   <div className="w3-col m4 w3-padding-32" style={{paddingBottom:"0px",marginBottom:"0px",padding:"10px"}}>
    
	<video src="images/given.mp4" loop autoPlay muted style={{ border:"1px solid #ccc",width: '100%', background: 'black',height:"400px"}} ></video>
	
   </div>
   
   <div className="w3-col m8" style={{display:"flex",justifyContent:"center",alignItems:"center",height:"400px"}}>
     <p className="w3-margin">
	 <h1>Meet Given Masango</h1>
	  Given Masango is an exceptional event specialist known for his creativity and professionalism. With a keen eye for detail and a passion for excellence, Given transforms ordinary events into extraordinary experiences. Discover how his unique approach can make your next event unforgettable.
	    <br/><br/>
		<center>
		 <button onClick={()=>{setCategory("given");}} className="w3-button w3-padding-large w3-white w3-border"><b>Explore Given</b></button>
        </center>
	 </p>
   </div>
   </div>
     {showCarousel && <ImageCarousel images={images} setShowCarousel={setShowCarousel} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>}
   
   </>);
  };
  
  export default IntroVideo;