import React from 'react';
import './SocialMediaLinks.css'; // Ensure custom styles are in this file
import TikTokIcon from "./TikTokIcon";
const SocialMediaLinks = () => {
  return (
  <>
    <div className="social-media-links">
      <a href="https://www.facebook.com/p/Brands-Fountains-Deco-Wedding-Coordination-100063954186870/" target="_blank" rel="noopener noreferrer" className="social-icon facebook">
        <i className="fa fa-facebook"></i>
      </a>
      <a href="https://x.com/i/flow/login?redirect_after_login=%2Fgiven19644" target="_blank" rel="noopener noreferrer" className="social-icon twitter">
        <i className="fa fa-twitter"></i>
      </a>
      <a href="https://www.instagram.com/brands_fountains/" target="_blank" rel="noopener noreferrer" className="social-icon instagram">
        <i className="fa fa-instagram"></i>
      </a>
      <a href="https://www.linkedin.com/posts/given-masango-27aa3815b_given-masango-the-founder-of-brands-fountains-activity-6544660386614923264-iia5/?trk=public_profile_share_view&originalSubdomain=za" target="_blank" rel="noopener noreferrer" className="social-icon linkedin">
        <i className="fa fa-linkedin"></i>
      </a>
      <a href="https://www.tiktok.com/@given_masango01" target="_blank" rel="noopener noreferrer" className="social-icon tiktok">
        <TikTokIcon/>
      </a>
    </div>
	<br/>
	<font>All rights reserved. Copyright&copy; 2024</font>
	<br/>
	</>
  );
}

export default SocialMediaLinks;
