import React, { useState } from 'react';
import './EnquiryForm.css'; // Create this CSS file for custom styles
import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';

const EnquiryForm = ({setEnquire}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
	  
    e.preventDefault();
	setEnquire(false);
		
    Notiflix.Loading.standard('Submitting...');
    const templateParams = {
      name,
      email,
      message,
    };

    emailjs.send('service_1gmfr9p', 'template_iosz5h6', templateParams, 'oHDaECTPiJ7CvCMvW')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
		Notiflix.Notify.success('Your message was sent successfully!');
		Notiflix.Loading.remove();
      }, (error) => {
		
        console.log('FAILED...', error);
		Notiflix.Notify.failure('There was a problem sending your message!');
		Notiflix.Loading.remove();
      });
  };

  return (
  <div style={{background:"rgba(0,0,0,0.4)",position:"fixed",top:"0",left:"0",zIndex:"999999",width:"100vw",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center"}}>

    <div className="inquiry-form">
	  <font onClick={()=>{setEnquire(false);}} style={{cursor:"pointer",fontSize:"24px",position:"absolute",top:"10px",right:"20px"}}>
		&times;
	  </font>
      <h2>Enquire</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
		  num="0761766240"
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>

        <button type="submit">Submit</button>
      </form>
    </div>
	</div>
  );
};

export default EnquiryForm;
