import React from 'react';
import Header from './Header';
import BlogEntry from './BlogEntry';
import IntroductionMenu from './IntroductionMenu';
import Footer from './Footer';
import IntroVideo from "./IntroVideo";
import './App.css'; // Add custom styles if needed
import Banner from './Banner';
import Cover from "./images/cover3.jpg";

//title, subtitle, backgroundImage
const App = () =>{
	const images = {
    weddings: [
      'images/weddings/1.jpeg',
      'images/weddings/2.jpeg',
      'images/weddings/3.jpeg',
	  'images/weddings/4.jpeg',
	  'images/weddings/5.jpeg',
	  'images/weddings/6.jpeg',
	  'images/weddings/7.jpeg',
	  'images/weddings/8.jpeg',
	  
    ],
    birthdays: [
	   'images/birthdays/1.jpg',
	   'images/birthdays/2.jpg',
	   'images/birthdays/3.jpg',
	   'images/birthdays/4.jpg',
	   'images/birthdays/5.jpg',
	   'images/birthdays/6.jpg',
	   'images/birthdays/7.jpg',
	   'images/birthdays/8.jpg',
	   'images/birthdays/9.jpg',
	   'images/birthdays/10.jpg',
	   'images/birthdays/11.jpg',
	   'images/birthdays/12.jpg',
	   'images/birthdays/13.jpg',
	   'images/birthdays/14.jpg',

    ],
    corporate: [
      'images/corporate/1.mp4',
      'images/corporate/2.mp4',
	  'images/corporate/3.mp4',
	  'images/corporate/4.mp4',
	  'images/corporate/5.mp4',
	  'images/corporate/1.jpeg',
	  'images/corporate/2.jpeg',
	  'images/corporate/3.jpeg',
	  'images/corporate/4.jpeg',
    ],
    graduations: [
      'images/graduations/1.jpg',
      'images/graduations/2.jpg',
	  'images/graduations/3.jpg',
	  'images/graduations/4.jpg',
    ],
	  flowers: [
      'images/flowers/1.mp4',
      'images/flowers/2.mp4',
	  'images/flowers/3.mp4',
	  'images/flowers/4.mp4',
    ],
	 given: [
		'images/given/1.jpeg',
		'images/given/2.jpeg',
		'images/given/3.jpeg',
		'images/given/4.jpeg',
		'images/given/5.jpeg',
		'images/given/6.jpeg',

	
    ],
  };

	return(
  <div className="App w3-light-grey">
    <Header images={images} />
    <Banner title="Brand's Fountains Decor" subtitle="& Event Coordinations" backgroundImage={Cover}/>
	<br/>
    <div className="w3-content" style={{ maxWidth: '1400px' }}>
      <div className="w3-row">
       <div className="w3-col m4 14">

        </div>
		<div className="w3-col m3 14">
          <BlogEntry
			more="birthdays"		  
			images={images}
            title="Birthdays" 
            imgSrc="images/birthdays/1.jpg" 
          
		  />
		  </div>
		  <div className="w3-col m3 14">
		     <BlogEntry
			more="corporate"
		    images={images}
            title="Corporate Events" 
            imgSrc="images/corporate/1.jpg"
			
		  />
		  </div>
        	  <div className="w3-col m3 14">
		     <BlogEntry
			more="weddings"
		    images={images}
            title="Wedding Events" 
            imgSrc="images/weddings/2.jpg" 
        />
		</div>
		 	  <div className="w3-col m3 14">
		     <BlogEntry
			more="flowers"
			isVideo="true"
		    images={images}
            title="Flower Arrangements" 
            videoSrc="images/flowers/1.mp4" 
        />
		
		  </div>
		  	  <div className="w3-col m3 14">
		     <BlogEntry
			more="graduations"
		    images={images}
            title="Graduations" 
            imgSrc="images/graduations/1.jpg" 
          />
		  </div>
		 
        </div>
		</div>
		<IntroVideo images={images}/>
		<Footer />
  </div>
);};

export default App;
