import React from 'react';
import SocialMediaLinks from "./SocialMediaLinks";
const Footer = () => (
  <>

  <footer className="footer w3-container w3-dark-grey w3-padding-32 w3-margin-top">
 
   <SocialMediaLinks/>
   <p>Developed by Victor Sepeng <a href="https://www.w3schools.com/w3css/default.asp" target="_blank" rel="noopener noreferrer"></a></p>
  </footer>
  </>
);

export default Footer;
