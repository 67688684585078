import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ImageCarousel.css'; // Create this CSS file for custom styles

const ImageCarousel = ({ images, setShowCarousel, selectedCategory, setSelectedCategory }) => {

  const renderMedia = (media, index) => {
    const isVideo = media.endsWith('.mp4');
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
        key={index}
      >
        {isVideo ? (
          <video
            muted
			loop
			autoplay="true"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            alt={`${selectedCategory} ${index + 1}`}
          >
            <source src={media} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            src={media}
            alt={`${selectedCategory} ${index + 1}`}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="image-carousel">
      <div style={{
        background: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        padding: "10px"
      }}>
        <h3 style={{
          textTransform: "uppercase",
          color: "white",
          fontSize: "16px",
          display: "none",
          position: "absolute",
          top: "8px",
          cursor: "pointer",
          left: "45px",
          zIndex: "9999"
        }}>{selectedCategory}</h3>
        <font
          onClick={() => { setShowCarousel(false); }}
          style={{
            color: "white",
            fontSize: "24px",
            position: "absolute",
            top: "10px",
            cursor: "pointer",
            left: "15px",
            zIndex: "9999"
          }}>
          &times;
        </font>

        <div style={{ maxWidth: "600px" }}>
          <Carousel showArrows={true} infiniteLoop={true} showThumbs={false}>
            {images[selectedCategory].map((media, index) => renderMedia(media, index))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
